export enum CurrencyEnum {
  CURRENCY_TYPE_BOINTS = 1,
  CURRENCY_TYPE_FIAT = 2,
  CURRENCY_TYPE_PENDING = 3,
}

export const currencyMapper: { [key: string]: string } = {
  [CurrencyEnum.CURRENCY_TYPE_BOINTS]: "Boints",
  [CurrencyEnum.CURRENCY_TYPE_FIAT]: "USD",
  [CurrencyEnum.CURRENCY_TYPE_PENDING]: "Pending",
}

export enum BalanceEnum {
  BALANCE_TYPE_ADD_PER_AD = 1,
  BALANCE_TYPE_ADD_PER_TAPJOY = 2,
  BALANCE_TYPE_ADD_PER_FYBER = 3,
  BALANCE_TYPE_ADD_PER_IRON_SOURCE = 4,
  BALANCE_TYPE_ADD_PER_POLLFISH_VIA_SURVEY = 5,
  BALANCE_TYPE_ADD_PER_POLLFISH_VIA_OFFERWALL = 6,
  BALANCE_TYPE_ADD_PER_REVU_VIA_OFFER = 7,
  BALANCE_TYPE_ADD_PER_REVU_VIA_CUSTOMER = 8,
  BALANCE_TYPE_ADD_PER_MAX = 9,
  BALANCE_TYPE_ADD_PER_ADJOE = 10,
  BALANCE_TYPE_WITHDRAWAL = 11,
  BALANCE_TYPE_REDUCE_PER_REVU_VIA_OFFER_REVERTED = 12,
  BALANCE_TYPE_REDUCE_PER_REVU_VIA_CUSTOMER_REVERTED = 13,
  BALANCE_TYPE_WITHDRAWAL_BONUS = 14,
  BALANCE_TYPE_SPIN_THE_WHEEL = 15,
  BALANCE_TYPE_ADD_PER_AYE = 16,
  BALANCE_TYPE_ADD_PER_ADGEM = 17,
  BALANCE_TYPE_REDUCE_PER_AYE = 18,
  BALANCE_TYPE_ADD_COMPLETED_PER_INBRAIN = 19,
  BALANCE_TYPE_ADD_DISQUALIFIED_PER_INBRAIN = 20,
  BALANCE_TYPE_WITHDRAWAL_DECLINED = 21,
  BALANCE_TYPE_REFERRAL_BONUS = 22,
  BALANCE_TYPE_COMPLETE_PROFILE = 23,
  BALANCE_TYPE_DAILY_TOURNAMENT = 24,
  BALANCE_TYPE_TEAM_TOURNAMENT = 25,
  BALANCE_TYPE_ADJOE_BONUS = 26,
  BALANCE_TYPE_ADD_PER_TEST_OFFERWALL = 27,
  BALANCE_TYPE_ADD_BY_ADMIN = 31,
  BALANCE_TYPE_REDUCE_BY_ADMIN = 32,
  BALANCE_TYPE_DOUBLE_BOINTS = 33,
  BALANCE_TYPE_FROM_PENDING_BALANCE = 34,
  BALANCE_TYPE_ADD_RANK_REWARD_PER_INBRAIN = 35,
  BALANCE_TYPE_EARNED_BONUS = 36,
  BALANCE_TYPE_MOTIVATIONAL_CURRENCY_EARNED = 37,
  BALANCE_TYPE_HRS_LEVEL_BASED = 38,
  BALANCE_TYPE_HRS_TIME_BASED = 39,
  BALANCE_TYPE_HRS_IAP_BASED = 40,
  BALANCE_TYPE_HRS_VIDEO_BASED = 41,
  BALANCE_TYPE_ADD_PER_MAF = 42,
  BALANCE_TYPE_ADD_PER_OFFERTORO = 43,
  BALANCE_TYPE_HRS_DAILY_REWARD = 44,
  BALANCE_TYPE_ADD_PER_ADGATE = 45,
  BALANCE_TYPE_ADD_PER_FARLY = 46,
  BALANCE_TYPE_ADD_PER_ADBREAK = 47,
  BALANCE_TYPE_ADD_PER_BITLABS = 48,
}

export const BalanceMapper: {[key: number]: string} = {
  [BalanceEnum.BALANCE_TYPE_ADD_PER_AD]: 'BALANCE_TYPE_ADD_PER_AD',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_TAPJOY]: 'BALANCE_TYPE_ADD_PER_TAPJOY',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_FYBER]: 'BALANCE_TYPE_ADD_PER_FYBER',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_IRON_SOURCE]:
    'BALANCE_TYPE_ADD_PER_IRON_SOURCE',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_POLLFISH_VIA_SURVEY]:
    'BALANCE_TYPE_ADD_PER_POLLFISH_VIA_SURVEY',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_POLLFISH_VIA_OFFERWALL]:
    'BALANCE_TYPE_ADD_PER_POLLFISH_VIA_OFFERWALL',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_REVU_VIA_OFFER]:
    'BALANCE_TYPE_ADD_PER_REVU_VIA_OFFER',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_REVU_VIA_CUSTOMER]:
    'BALANCE_TYPE_ADD_PER_REVU_VIA_CUSTOMER',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_MAX]: 'BALANCE_TYPE_ADD_PER_MAX',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_ADJOE]: 'BALANCE_TYPE_ADD_PER_ADJOE',
  [BalanceEnum.BALANCE_TYPE_WITHDRAWAL]: 'BALANCE_TYPE_WITHDRAWAL',
  [BalanceEnum.BALANCE_TYPE_REDUCE_PER_REVU_VIA_OFFER_REVERTED]:
    'BALANCE_TYPE_REDUCE_PER_REVU_VIA_OFFER_REVERTED',
  [BalanceEnum.BALANCE_TYPE_REDUCE_PER_REVU_VIA_CUSTOMER_REVERTED]:
    'BALANCE_TYPE_REDUCE_PER_REVU_VIA_CUSTOMER_REVERTED',
  [BalanceEnum.BALANCE_TYPE_WITHDRAWAL_BONUS]: 'BALANCE_TYPE_WITHDRAWAL_BONUS',
  [BalanceEnum.BALANCE_TYPE_SPIN_THE_WHEEL]: 'BALANCE_TYPE_SPIN_THE_WHEEL',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_AYE]: 'BALANCE_TYPE_ADD_PER_AYE',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_ADGEM]: 'BALANCE_TYPE_ADD_PER_ADGEM',
  [BalanceEnum.BALANCE_TYPE_REDUCE_PER_AYE]: 'BALANCE_TYPE_REDUCE_PER_AYE',
  [BalanceEnum.BALANCE_TYPE_ADD_COMPLETED_PER_INBRAIN]:
    'BALANCE_TYPE_ADD_COMPLETED_PER_INBRAIN',
  [BalanceEnum.BALANCE_TYPE_ADD_DISQUALIFIED_PER_INBRAIN]:
    'BALANCE_TYPE_ADD_DISQUALIFIED_PER_INBRAIN',
  [BalanceEnum.BALANCE_TYPE_WITHDRAWAL_DECLINED]:
    'BALANCE_TYPE_WITHDRAWAL_DECLINED',
  [BalanceEnum.BALANCE_TYPE_REFERRAL_BONUS]: 'BALANCE_TYPE_REFERRAL_BONUS',
  [BalanceEnum.BALANCE_TYPE_COMPLETE_PROFILE]: 'BALANCE_TYPE_COMPLETE_PROFILE',
  [BalanceEnum.BALANCE_TYPE_DAILY_TOURNAMENT]: 'BALANCE_TYPE_DAILY_TOURNAMENT',
  [BalanceEnum.BALANCE_TYPE_TEAM_TOURNAMENT]: 'BALANCE_TYPE_TEAM_TOURNAMENT',
  [BalanceEnum.BALANCE_TYPE_ADJOE_BONUS]: 'BALANCE_TYPE_ADJOE_BONUS',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_TEST_OFFERWALL]:
    'BALANCE_TYPE_ADD_PER_TEST_OFFERWALL',
  [BalanceEnum.BALANCE_TYPE_ADD_BY_ADMIN]: 'BALANCE_TYPE_ADD_BY_ADMIN',
  [BalanceEnum.BALANCE_TYPE_REDUCE_BY_ADMIN]: 'BALANCE_TYPE_REDUCE_BY_ADMIN',
  [BalanceEnum.BALANCE_TYPE_DOUBLE_BOINTS]: 'BALANCE_TYPE_DOUBLE_BOINTS',
  [BalanceEnum.BALANCE_TYPE_FROM_PENDING_BALANCE]:
    'BALANCE_TYPE_FROM_PENDING_BALANCE',
  [BalanceEnum.BALANCE_TYPE_ADD_RANK_REWARD_PER_INBRAIN]:
    'BALANCE_TYPE_ADD_RANK_REWARD_PER_INBRAIN',
  [BalanceEnum.BALANCE_TYPE_EARNED_BONUS]: 'BALANCE_TYPE_EARNED_BONUS',
  [BalanceEnum.BALANCE_TYPE_MOTIVATIONAL_CURRENCY_EARNED]: 'BALANCE_TYPE_MOTIVATIONAL_CURRENCY_EARNED',
  [BalanceEnum.BALANCE_TYPE_HRS_LEVEL_BASED]: 'BALANCE_TYPE_HRS_LEVEL_BASED',
  [BalanceEnum.BALANCE_TYPE_HRS_TIME_BASED]: 'BALANCE_TYPE_HRS_TIME_BASED',
  [BalanceEnum.BALANCE_TYPE_HRS_IAP_BASED]: 'BALANCE_TYPE_HRS_IAP_BASED',
  [BalanceEnum.BALANCE_TYPE_HRS_VIDEO_BASED]: 'BALANCE_TYPE_HRS_VIDEO_BASED',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_MAF]: 'BALANCE_TYPE_ADD_PER_MAF',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_OFFERTORO]: 'BALANCE_TYPE_ADD_PER_OFFERTORO',
  [BalanceEnum.BALANCE_TYPE_HRS_DAILY_REWARD]: 'BALANCE_TYPE_HRS_DAILY_REWARD',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_ADGATE]: 'BALANCE_TYPE_ADD_PER_ADGATE',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_FARLY]: 'BALANCE_TYPE_ADD_PER_FARLY',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_ADBREAK]: 'BALANCE_TYPE_ADD_PER_ADBREAK',
  [BalanceEnum.BALANCE_TYPE_ADD_PER_BITLABS]: 'BALANCE_TYPE_ADD_PER_BITLABS',
};

export const positiveBalanceTypes: BalanceEnum[] = [
  BalanceEnum.BALANCE_TYPE_ADD_PER_AD,
  BalanceEnum.BALANCE_TYPE_ADD_PER_TAPJOY,
  BalanceEnum.BALANCE_TYPE_ADD_PER_FYBER,
  BalanceEnum.BALANCE_TYPE_ADD_PER_IRON_SOURCE,
  BalanceEnum.BALANCE_TYPE_ADD_PER_POLLFISH_VIA_SURVEY,
  BalanceEnum.BALANCE_TYPE_ADD_PER_POLLFISH_VIA_OFFERWALL,
  BalanceEnum.BALANCE_TYPE_ADD_PER_REVU_VIA_OFFER,
  BalanceEnum.BALANCE_TYPE_ADD_PER_REVU_VIA_CUSTOMER,
  BalanceEnum.BALANCE_TYPE_ADD_PER_MAX,
  BalanceEnum.BALANCE_TYPE_ADD_PER_ADJOE,
  BalanceEnum.BALANCE_TYPE_ADD_BY_ADMIN,
  BalanceEnum.BALANCE_TYPE_WITHDRAWAL_DECLINED,
  BalanceEnum.BALANCE_TYPE_REFERRAL_BONUS,
  BalanceEnum.BALANCE_TYPE_COMPLETE_PROFILE,
  BalanceEnum.BALANCE_TYPE_DAILY_TOURNAMENT,
  BalanceEnum.BALANCE_TYPE_TEAM_TOURNAMENT,
  BalanceEnum.BALANCE_TYPE_WITHDRAWAL_BONUS,
  BalanceEnum.BALANCE_TYPE_SPIN_THE_WHEEL,
  BalanceEnum.BALANCE_TYPE_ADD_PER_AYE,
  BalanceEnum.BALANCE_TYPE_ADD_PER_ADGEM,
  BalanceEnum.BALANCE_TYPE_ADD_COMPLETED_PER_INBRAIN,
  BalanceEnum.BALANCE_TYPE_ADD_DISQUALIFIED_PER_INBRAIN,
  BalanceEnum.BALANCE_TYPE_ADD_RANK_REWARD_PER_INBRAIN,
  BalanceEnum.BALANCE_TYPE_ADD_PER_TEST_OFFERWALL,
  BalanceEnum.BALANCE_TYPE_ADJOE_BONUS,
  BalanceEnum.BALANCE_TYPE_DOUBLE_BOINTS,
  BalanceEnum.BALANCE_TYPE_ADD_PER_MAF,
  BalanceEnum.BALANCE_TYPE_ADD_PER_OFFERTORO,
  BalanceEnum.BALANCE_TYPE_ADD_PER_ADGATE,
  BalanceEnum.BALANCE_TYPE_ADD_PER_FARLY,
  BalanceEnum.BALANCE_TYPE_ADD_PER_ADBREAK,
  BalanceEnum.BALANCE_TYPE_ADD_PER_BITLABS
];

export const negativeBalanceTypes: BalanceEnum[] = [
  BalanceEnum.BALANCE_TYPE_WITHDRAWAL,
  BalanceEnum.BALANCE_TYPE_REDUCE_PER_REVU_VIA_OFFER_REVERTED,
  BalanceEnum.BALANCE_TYPE_REDUCE_PER_REVU_VIA_CUSTOMER_REVERTED,
  BalanceEnum.BALANCE_TYPE_REDUCE_BY_ADMIN,
  BalanceEnum.BALANCE_TYPE_REDUCE_PER_AYE,
];
