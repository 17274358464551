import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import { AppStore } from '../app';
import {
  RequestUpdateSettingsDto,
} from '../../proto/leaderboard_pb';
import {
  GetSettingsRequest,
  GetSettingsResponse,
  GetSettingsWithPaginationRequest,
  GetSettingsWithPaginationResponse,
} from '../../proto/settings_pb';
import { 
  SettingsFrontendSettingsGetByLastVersionRoute,
  SettingsFrontendSettingsUpdateRoute,
  SettingsFrontendSettingsGetByCertainVersionRoute,
  SettingsFrontendSettingsGetAllVersionsRoute,
  SettingsSettingsGetByMicroservicesRoute,
  SettingsSettingsGetWithPaginationRoute,
  SettingsSettingsUpdateRoute,
  SettingsFrontendSettingsDeleteByVersionRoute,
} from '@boints/grpc';
import { WebSocketService } from '../../services/transport';

export const hiddenSettings = [
  'BOINTS_POOL_START_AMOUNT_TEAM',
  'INCREASE_EVERY_TEAM',
  'BOINTS_POOL_INCREASE_TEAM',
  'DEFAULT_TOKENS_BALANCE',
];

export class SettingsStore {
  public error: RpcError | null = null;

  public featureToggleSettings: GetSettingsResponse.AsObject = {
    settingsList: [],
  };

  public settings: GetSettingsWithPaginationResponse.AsObject = {
    settingsList: [],
    totalcount: 0,
    page: 1,
    perpage: 10,
  };

  public frontendSettings: SettingsFrontendSettingsGetByLastVersionRoute.ResponseType['settings'] = '';
  public frontendSettingsVersions: SettingsFrontendSettingsGetAllVersionsRoute.ResponseType['versions'] = [];

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getSettings(dto: GetSettingsWithPaginationRequest.AsObject & { amountperpage?: number, pagenumber?: number }) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { settings, page, perPage, totalCount }: SettingsSettingsGetWithPaginationRoute.ResponseType = yield WebSocketService
        .sendRequest<SettingsSettingsGetWithPaginationRoute.ResponseType, SettingsSettingsGetWithPaginationRoute.RequestType>({
          method: 'settings_getWithPagination',
          data: {
            page: dto.pagenumber || 1,
            perPage: dto.amountperpage || 10,
            microservice: dto.microservice,
          },
        });

      this.settings = {
        settingsList: settings
          .filter((item) => !hiddenSettings.includes(item.key))
          .map(({ description, dictionaryType, id, key, microservice, value }) => {
            return {
              id,
              key,
              value,
              description,
              microservice,
              dictionarytype: dictionaryType,
            };
          }),
        page,
        perpage: perPage,
        totalcount: totalCount,
      };
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getSettingsByMicroservices(dto: GetSettingsRequest.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { settings }: SettingsSettingsGetByMicroservicesRoute.ResponseType = yield WebSocketService
        .sendRequest<SettingsSettingsGetByMicroservicesRoute.ResponseType, SettingsSettingsGetByMicroservicesRoute.RequestType>({
          method: 'settings_getByMicroservices',
          data: {
            microservices: dto.microservicesList,
          },
        });

      this.featureToggleSettings = {
        settingsList: settings.map(({ description, dictionaryType, id, key, microservice, value }) => {
          return {
            id,
            key,
            value,
            description,
            microservice,
            dictionarytype: dictionaryType,
          };
        }),
      };
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateSettings(dto: RequestUpdateSettingsDto.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: SettingsSettingsUpdateRoute.ResponseType = yield WebSocketService
        .sendRequest<SettingsSettingsUpdateRoute.ResponseType, SettingsSettingsUpdateRoute.RequestType>({
          method: 'settings_update',
          data: {
            id: dto.id,
            key: dto.key,
            value: dto.value,
          },
        });

      this.settings.settingsList = this.settings.settingsList.map((item) => {
        if (item.id === dto.id) {
          const { description, dictionaryType, id, key, microservice, value } = response;
          return {
            id,
            key,
            value,
            description,
            microservice,
            dictionarytype: dictionaryType,
          };
        }

        return item;
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateSettingsByMicroservice(dto: RequestUpdateSettingsDto.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: SettingsSettingsUpdateRoute.ResponseType = yield WebSocketService
        .sendRequest<SettingsSettingsUpdateRoute.ResponseType, SettingsSettingsUpdateRoute.RequestType>({
          method: 'settings_update',
          data: {
            id: dto.id,
            key: dto.key,
            value: dto.value,
          },
        });

      this.featureToggleSettings.settingsList = this.featureToggleSettings.settingsList.map((item) => {
        if (item.id === dto.id) {
          const { description, dictionaryType, id, key, microservice, value } = response;
          return {
            id,
            key,
            value,
            description,
            microservice,
            dictionarytype: dictionaryType,
          };
        }

        return item;
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getAllFrontendSettingsVersions() {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: SettingsFrontendSettingsGetAllVersionsRoute.ResponseType = yield WebSocketService
        .sendRequest<unknown, SettingsFrontendSettingsGetAllVersionsRoute.RequestType>({
          method: 'settings_getAllFrontendSettingsVersions',
          data: {},
        });

      this.frontendSettingsVersions = response.versions;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      })
    }

    this.rootStore.isLoading = false;
  }

  *getFrontendSettings({ version }: { version: string }) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: SettingsFrontendSettingsGetByCertainVersionRoute.ResponseType = yield WebSocketService
        .sendRequest<unknown, SettingsFrontendSettingsGetByCertainVersionRoute.RequestType>({
          method: 'settings_getFrontendSettings',
          data: {
            version,
          },
        });

      this.frontendSettings = JSON.stringify(response.settings, null, 4);
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateFrontendSettings(dto: SettingsFrontendSettingsUpdateRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      if (!(new RegExp(/[0-9]{1,5}\.[0-9]{1,5}\.[0-9]{1,5}/).test(dto.version))) {
        throw new Error('Invalid version format');
      }

      yield WebSocketService
        .sendRequest<unknown, SettingsFrontendSettingsUpdateRoute.RequestType>({
          method: 'settings_updateFrontendSettings',
          data: {
            version: dto.version,
            settings: JSON.stringify(JSON.parse(dto.settings)),
          },
        });

      if (!this.frontendSettingsVersions.includes(dto.version)) {
        this.frontendSettingsVersions.unshift(dto.version);
      }

      this.frontendSettings = dto.settings;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *deleteFrontendSettings(dto: SettingsFrontendSettingsDeleteByVersionRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<unknown, SettingsFrontendSettingsDeleteByVersionRoute.RequestType>({
          method: 'settings_deleteFrontendSettings',
          data: {
            version: dto.version,
          },
        });

        this.frontendSettingsVersions = this.frontendSettingsVersions.filter((version) => version !== dto.version);
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
