import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface DailyRewards {
  rewards: number[];
  repeatCycle: boolean;
}

interface OwnProps {
  bundleId: string;
  offerwallId: string;
  dailyRewards: DailyRewards;
  updateDailyRewardsAction: (params: DailyRewards & { bundleId: string; offerwallId: string; }) => any;
}

type Props = OwnProps;

export const DailyRewardsDialog: React.FC<Props> = ({
  dailyRewards,
  updateDailyRewardsAction,
  bundleId,
  offerwallId,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [rewards, setRewards] = useState<number[]>(dailyRewards.rewards);
  const [repeatCycle, setRepeatCycle] = useState<boolean>(dailyRewards.repeatCycle);

  // const { notificationStore } = useStores();

  useEffect(() => {
    setRewards(dailyRewards.rewards);
  }, [dailyRewards.rewards]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseModal = () => {
    setOpenDialog(false);
  };
  
  const handleAction = () => {
    setOpenDialog(false);
    updateDailyRewardsAction({ repeatCycle, rewards, bundleId, offerwallId });
  };

  const handleChangeReward = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      rewards[index] = +e.target.value;
      setRewards([...rewards]);
    }
  };

  const handleAddReward = () => {
    setRewards([...rewards, 0]);
  };

  const handleRemoveReward = (index) => {
    setRewards(rewards.filter((v, i) => i !== index));
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseModal}
        aria-labelledby="test-dialog-title"
        aria-describedby="test-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="test-dialog-description">
            Edit daily rewards for {offerwallId} {bundleId}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          {rewards.map((reward, i) => (
            <div className='row flex-nowrap align-items-center' key={i}>
              <div className="col-12 flex-shrink-1" style={{ padding: '8px 0' }}>
                <TextField
                  type='text'
                  label={`Reward ${i + 1}`}
                  placeholder={`Reward ${i + 1}`}
                  onChange={(e) => handleChangeReward(e, i)}
                  value={reward}
                />
              </div>
              <div className="col-auto flex-shrink-0">
                <Button color='warning' variant='contained' onClick={() => handleRemoveReward(i)}><DeleteIcon /></Button>
              </div>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddReward} color="secondary" autoFocus>
            Add reward
          </Button>
        </DialogActions>
        <DialogContent>
          <FormControlLabel
            label="Repeat cycle"
            control={
              <Checkbox onChange={(e) => setRepeatCycle(e.target.checked)} checked={repeatCycle}/>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAction} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Button onClick={handleClickOpen} variant='outlined' color='success'>Edit Daily Rewards</Button>
    </>
  );
};
