import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

export const getCustomTableStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxHeight: '100vh',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      // height: 'calc(100 - 122px)',
      flex: 1,
    },
    search: {
      width: '100%',
      margin: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
    },
    tableCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tableCellPointer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    tableItem: {
      '&:nth-child(2n)': {
        background: 'rgba(0, 0, 0, 0.04)',
      },
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.1) !important',
      },
    },
    contentAlignStart: {
      justifyContent: 'start',
    },
    contentAlignCenter: {
      justifyContent: 'center',
    },
    searchInput: {
      width: '100%',
      maxWidth: 400,
    },
    icon: {
      marginLeft: theme.spacing(0.5),
      fontSize: '14px !important',
      transition: '0.4s',
    },
    arrowDown: {
      marginLeft: theme.spacing(0.5),
      fontSize: '14px !important',
      transition: '0.4s',
      transform: 'rotate(180deg)',
    },
    arrow: {
      transition: '.4s',
      position: 'absolute',
      top: 0,
      bottom: 0,
      margin: 'auto'
    },
    box: {
      display: 'flex',
      alignItems: 'center',
    },
    pageActionBox: {
      margin: theme.spacing(2),
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    pageHeader: {
      display: 'flex',
    },
    infoBlock: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    pagination: {
      '& > div > p': {
        margin: 0,
      }
    }
  })
);
