import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const drawerWidth = 240;

export const getDrawerStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: {
      padding: theme.spacing(2),
    },
    drawerPaper: {
      width: drawerWidth,
    },
    userInfo: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(1.5),
    },

    listItemActive: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
      textDecoration: 'none',
      borderRadius: theme.spacing(1.5),
      marginBottom: theme.spacing(1),
      width: '100%',
    },
    list: {
      paddingLeft: `${theme.spacing(2)} !important`,
      paddingRight: `${theme.spacing(2)} !important`,
    },
    listItem: {
      borderRadius: theme.spacing(1.5),
      marginBottom: theme.spacing(1),
      color: `${theme.palette.text.primary} !important`,
      textDecoration: 'none',
      width: '100%',
    },
    listItemIcon: {
      color: 'inherit !important',
      minWidth: `30px !important`,
    },
    navSection: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
      marginTop: theme.spacing(2),
    },
    themeSwitcher: {
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
  })
);
