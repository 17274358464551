export const reward = {
  findAll: {
    microserviceName: 'reward',
    serviceName: 'RewardStageService',
    method: 'findAll',
  },
  create: {
    microserviceName: 'reward',
    serviceName: 'RewardStageService',
    method: 'create',
  },
  update: {
    microserviceName: 'reward',
    serviceName: 'RewardStageService',
    method: 'update',
  },
  delete: {
    microserviceName: 'reward',
    serviceName: 'RewardStageService',
    method: 'delete',
  },
  getStepBonusConfig: {
    microserviceName: 'reward',
    serviceName: 'BonusService',
    method: 'getStepBonusConfig',
  },
  createStepBonusConfig: {
    microserviceName: 'reward',
    serviceName: 'BonusService',
    method: 'createStepBonusConfig',
  },
  editStepBonusConfig: {
    microserviceName: 'reward',
    serviceName: 'BonusService',
    method: 'editStepBonusConfig',
  },
  deleteStepBonusConfig: {
    microserviceName: 'reward',
    serviceName: 'BonusService',
    method: 'deletStepBonusConfig',
  },
  offerwallSchemasFindAll: {
    microserviceName: 'reward',
    serviceName: 'OfferWallSchemasService',
    method: 'findAll',
  },
  offerwallSchemasCreate: {
    microserviceName: 'reward',
    serviceName: 'OfferWallSchemasService',
    method: 'create',
  },
  offerwallSchemasUpdate: {
    microserviceName: 'reward',
    serviceName: 'OfferWallSchemasService',
    method: 'update',
  },
  isSendRewardWorkerEnabled: {
    microserviceName: 'reward',
    serviceName: 'SendRewardsWorkerService',
    method: 'isWorkerEnabled',
  },
  sendRewardsWorkerEnable: {
    microserviceName: 'reward',
    serviceName: 'SendRewardsWorkerService',
    method: 'enableWorker',
  },
  sendRewardsWorkerDisable: {
    microserviceName: 'reward',
    serviceName: 'SendRewardsWorkerService',
    method: 'disableWorker',
  },
  offerwallRewardsFindAll: {
    microserviceName: 'reward',
    serviceName: 'OfferWallRewardsService',
    method: 'findAll',
  },
  stageOrderFindAll: {
    microserviceName: 'reward',
    serviceName: 'MCStageOrderService',
    method: 'findAll',
  },
  stageOrderUpdate: {
    microserviceName: 'reward',
    serviceName: 'MCStageOrderService',
    method: 'update',
  },
  stageOrderRemove: {
    microserviceName: 'reward',
    serviceName: 'MCStageOrderService',
    method: 'deleteStageorder',
  },
  getUsersStats: {
    microserviceName: 'reward',
    serviceName: 'MCStageOrderService',
    method: 'getMcUsersStats',
  },
  findOneByOrderAndVersion: {
    microserviceName: 'reward',
    serviceName: 'MCStageOrderService',
    method: 'findOneByOrderAndVersion',
  },
  getDoubleBoints: {
    microserviceName: 'reward',
    serviceName: 'DoubleBointsService',
    method: 'get',
  },
  updateDoubleBoints: {
    microserviceName: 'reward',
    serviceName: 'DoubleBointsService',
    method: 'update',
  },
  getUserStats: {
    microserviceName: 'reward',
    serviceName: 'MCStageOrderService',
    method: 'getMcUserStats',
  }
}