export const offerwall = {
  updateGame: {
    microserviceName: 'offerwall',
    serviceName: 'AdminService',
    method: 'updateGame',
  },
  getOfferwalls: {
    microserviceName: 'offerwall',
    serviceName: 'AdminService',
    method: 'getOfferwalls',
  },
  getRewards: {
    microserviceName: 'offerwall',
    serviceName: 'AdminService',
    method: 'getRewards',
  },
  addReward: {
    microserviceName: 'offerwall',
    serviceName: 'AdminService',
    method: 'addReward',
  },
  updateReward: {
    microserviceName: 'offerwall',
    serviceName: 'AdminService',
    method: 'updateReward',
  },
  addOfferwall: {
    microserviceName: 'offerwall',
    serviceName: 'AdminService',
    method: 'addOfferwall',
  },
  addGame: {
    microserviceName: 'offerwall',
    serviceName: 'AdminService',
    method: 'addGame',
  },
  getDailyRewards: {
    microserviceName: 'offerwall',
    serviceName: 'AdminService',
    method: 'getDailyRewards',
  },
  updateDailyRewards: {
    microserviceName: 'offerwall',
    serviceName: 'AdminService',
    method: 'updateDailyRewards',
  },
  importCsv: {
    microserviceName: 'offerwall',
    serviceName: 'AdminService',
    method: 'importCsv',
  },
  deleteGame: {
    microserviceName: 'offerwall',
    serviceName: 'AdminService',
    method: 'deleteGame',
  },
  updateGamesOrder: {
    microserviceName: 'offerwall',
    serviceName: 'AdminService',
    method: 'updateGamesOrder',
  },
};