import React, {useEffect, useState} from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Box,
  DialogTitle,
  TextField,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Paper,
  Icon,
  Checkbox,
  Tooltip,
  InputAdornment,
} from '@mui/material';
import { Delete, Edit, ExpandMore, Launch, Error as ErrorIcon, PaymentsOutlined, Payments } from '@mui/icons-material';
import { getStageEditingStyles } from './mc-stage-edit.styles';
import AddIcon from '@mui/icons-material/Add';
import { MCGroup, MCStage, MCStep, StageOrder } from '@boints/grpc/lib/proto-interfaces/reward';

const offerwalls = [
  {
    value: 'ADJOE',
    label: 'Adjoe',
  },
  {
    value: 'TAPJOY',
    label: 'Tapjoy',
  },
  {
    value: 'HRS',
    label: 'HRS',
  },
  {
    value: 'IRON_SOURCE',
    label: 'Iron Source',
  },
  {
    value: 'AD',
    label: 'AD',
  },
  {
    value: 'FYBER',
    label: 'Fyber',
  },
  {
    value: 'POLLFISH',
    label: 'Pollfish',
  },
  {
    value: 'REVU',
    label: 'RevU',
  },
  {
    value: 'MAX',
    label: 'MAX',
  },
  {
    value: 'AYE',
    label: 'AYE',
  },
  {
    value: 'ADGEM',
    label: 'AdGem',
  },
  {
    value: 'MAF',
    label: 'MAF',
  },
  {
    value: 'OFFERTORO',
    label: 'OfferToro',
  },
  {
    value: 'ADGATE',
    label: 'AdGate',
  },
  {
    value: 'FARLY',
    label: 'Farly',
  },
  {
    value: 'ADBREAK',
    label: 'AdBreak',
  },
  {
    value: 'BITLABS',
    label: 'BitLabs',
  },
];

const rewardType = [
  {
    value: 'BOINTS',
    label: 'Boints Based: ',
  },
  {
    value: 'POSTBACK',
    label: 'Postback Based:',
  },
];

const valueType = [
  {
    value: 'summary',
    label: 'No Random',
  },
  {
    value: 'random',
    label: 'Random 0.8-1.2',
  },
];

interface OwnProps {
  updatableObject: StageOrder;
  updateItemAction?: (item: StageOrder) => void;
  isCreate?: boolean;
  disabled?: boolean;
  btnText?: any;
  fetchEach?: () => void;
}

type Props = OwnProps;

export const McV2Edit: React.FC<Props> = ({
  updatableObject,
  updateItemAction,
  isCreate,
  disabled,
  btnText,
  fetchEach
}) => {
  const classes = getStageEditingStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<string[]>([]);
  const [currItem, setCurrItem] = useState<StageOrder>(updatableObject);

  const handleClickOpen = () => {
    if (fetchEach) {
      fetchEach();
    }
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    const errors = validation(currItem);
    if (errors.length > 0) {
      setShowErrors(errors);
      return;
    }
    if (updateItemAction) {
      updateItemAction(currItem);
    }
    setOpen(false);
  };

  const getRewardsSum = (): number => {
    let sum = 0;
    if (!currItem) return 0;
    for (let i = 0; i < currItem.stages.length; i++) {
      for (let j = 0; j < currItem.stages[i].groups.length; j++) {
        for (let h = 0; h < currItem.stages[i].groups[j].steps.length; h++) {
          sum += currItem.stages[i].groups[j].steps[h].reward;
        }
      }
    }
    return sum;
  };

  const roundToUsd = (num: number): number => {
    return Math.floor(num * 100) / 10000;
  };

  const validation = (item: StageOrder): string[] => {
    const errors: string[] = [];
    if (item.startingBonus < 0) {
      errors.push(`[BONUS]: Starting Bonus can't be negative.`);
    }
    if (item.stages.length === 0) {
      errors.push(`[STAGE]: You need at least one Stage.`);
    }
    for (let i = 0; i < item.stages.length; i++) {
      if (item.stages[i].groups.length === 0) {
        errors.push(`[GROUP]: You need at least one Group in Stage #${i + 1}.`);
      }
      for (let j = 0; j < item.stages[i].groups.length; j++) {
        if (item.stages[i].groups[j].steps.length === 0) {
          errors.push(`[STEP]: You need at least one Steps in Group #${j + 1}.`);
        }
        for (let h = 0; h < item.stages[i].groups[j].steps.length; h++) {
          if (item.stages[i].groups[j].steps[h].value <= 0) {
            errors.push(`[Stage ${i + 1} - Group ${j + 1} - Step ${h}]: ${currItem.stages[i].valueType == 'POSTBACK' ? "\"Postback to receive\"" : "\"Boints need to earn\""} can't be less than 0.`);
          }
          if (item.stages[i].groups[j].steps[h].reward <= 0) {
            errors.push(`[Stage ${i + 1} - Group ${j + 1} - Step ${h}]: Reward can't be less than 0.`);
          }
          if (
            item.stages[i].valueType === 'POSTBACK'
            && item.stages[i].groups[j].steps[h].rewardType === 'summary'
            && item.stages[i].groups[j].steps[h].reward % item.stages[i].groups[j].steps[h].value !== 0) {
            errors.push(`[Stage ${i + 1} - Group ${j + 1} - Step ${h}]: The step reward is an irrational number. Please change the step reward setup so the postback reward can be calculated properly.`);
          }
        }
      }
    }
    return errors;
  };

  const StageRender = (stagesList: MCStage[]) => {
    return stagesList.map((stage, stageId) => (
    <Accordion key={(stage as any).id} style={{ background: 'unset'}}>
      <AccordionSummary expandIcon={<ExpandMore />} style={{ flexDirection: 'row-reverse' }}>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
          <Typography whiteSpace={'nowrap'}><b>{`Stage: ${stageId + 1}`}</b></Typography>
          <TextField
                select
                disabled={!updateItemAction}
                className={classes.inputEditing}
                defaultValue={stage.offerwall}
                style={{ minWidth: '150px'}}
                name="offerwall"
                // value={stage.offerwall}
                variant="standard"
                size='small'
                onChange={(e) => {
                  currItem.stages[stageId].offerwall = e.target.value;
                  setCurrItem({...currItem});
                }}
                onClick={(e) => e.stopPropagation()}
              >
              {offerwalls.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              disabled={!updateItemAction}
              select
              defaultValue={stage.valueType}
              className={classes.inputEditing}
              style={{ marginLeft: '5px', marginRight: '5px' }}
              name="valueType"
              value={stage.valueType}
              variant="standard"
              size='small'
              onChange={(e) => {
                currItem.stages[stageId].valueType = e.target.value;
                setCurrItem({...currItem});
              }}
              onClick={(e) => e.stopPropagation()}
            >
            {rewardType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            </TextField>
          <IconButton style={{display: updateItemAction ? "block" : "none"}} onClick={(e) => {
            e.stopPropagation();
            currItem.stages = currItem.stages.filter((_, index) => index !== stageId);
            setCurrItem({...currItem});
            }}>
                <Delete color='error' />
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box >
          {GroupRender(stage.groups, stageId)}
          <Button style={{display: updateItemAction ? "block" : "none"}} onClick={() => {
            currItem.stages[stageId].groups.push({
              id: Math.random(),
              description: '',
              isRandom: false,
              steps: [],
            } as any);
            setCurrItem({...currItem});
          }}>Add Group</Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  ))}

  const GroupRender = (groupList: MCGroup[], stageId) => {
    return groupList.map((group, groupId) => (
        <Accordion key={(group as any).id}>
          <AccordionSummary expandIcon={<ExpandMore />} style={{ flexDirection: 'row-reverse' }}>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
              <Typography whiteSpace={'nowrap'}><b>Group: {groupId + 1}</b></Typography>
              <TextField
                disabled={!updateItemAction}
                className={classes.inputEditing}
                defaultValue={group.description}
                name="name"
                size='small'
                variant='standard'
                style={{ minWidth: '450px'}}
                onBlur={(e) => {
                    currItem.stages[stageId].groups[groupId].description = e.target.value;
                    setCurrItem({...currItem});
                  }}
                onClick={(e) => e.stopPropagation()}
              />
              <IconButton style={{display: updateItemAction ? "block" : "none"}} onClick={(e) => {
                e.stopPropagation();
                currItem.stages[stageId].groups = currItem.stages[stageId].groups.filter((_, index) => index !== groupId);
                setCurrItem({...currItem});
                }}>
                    <Delete color='error' />
              </IconButton>
              </Box>
            </AccordionSummary>
          <AccordionDetails>
            {/* <Box>
              {"Random: "}
              <Checkbox
              style={{ padding: '0px' }}
                checked={group.israndom}
                // onChange={handleChange}
                name="israndom"
                color="primary"/>
            </Box> */}
            {StepRender(group.steps, stageId, groupId)}
            <Button style={{display: updateItemAction ? "block" : "none"}} onClick={() => {
            currItem.stages[stageId].groups[groupId].steps.push({
              value: 0,
              reward: 0,
              rewardType: 'summary',
              valueType: '',
            });
            setCurrItem({...currItem});
          }}>Add Step</Button>
          </AccordionDetails>
        </Accordion>
    ))
  }

  const StepRender = (stepList: MCStep[], stageId, groupId) => {
    return stepList.map((step, stepId) => (
      <Box key={(step as any).id} style={{ marginLeft: '15px', paddingLeft: '15px', marginBottom: '5px', display: 'flex' }}>
        <Typography style={{ marginRight: '10px'}}><b>Step {stepId + 1}: </b></Typography>
        <Typography>{`${currItem.stages[stageId].valueType == 'POSTBACK' ? "Postback to receive: " : "Boints need to earn: "}`}</Typography>
        <TextField
          disabled={!updateItemAction}
          defaultValue={step.value}
          style={{ marginLeft: '5px', marginRight: '5px' }}
          name="value"
          size='small'
          variant='standard'
          onBlur={(e) => {
            currItem.stages[stageId].groups[groupId].steps[stepId].value = +e.target.value;
            setCurrItem({...currItem});
          }}
          onClick={(e) => e.stopPropagation()}
        />
        <Typography>{". Reward: "}</Typography>
        <TextField
          disabled={!updateItemAction}
          defaultValue={step.reward / 100}
          style={{ marginLeft: '5px', marginRight: '5px' }}
          name="reward"
          size='small'
          variant='standard'
          onBlur={(e) => {
            currItem.stages[stageId].groups[groupId].steps[stepId].reward = +e.target.value * 100;
            setCurrItem({...currItem});
          }}
          onClick={(e) => e.stopPropagation()}
        />
        $
        (
          <TextField
            disabled={!updateItemAction}
            select
            defaultValue={step.rewardType}
            style={{ marginLeft: '5px', marginRight: '5px' }}
            name="rewardType"
            value={step.rewardType}
            variant="standard"
            size='small'
            onChange={(e) => {
              currItem.stages[stageId].groups[groupId].steps[stepId].rewardType = e.target.value;
              setCurrItem({...currItem});
            }}
            onClick={(e) => e.stopPropagation()}
          >
          {valueType.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        )
        <IconButton style={{ display: updateItemAction ?  "block" : "none"}} onClick={(e) => {
          e.stopPropagation();
          currItem.stages[stageId].groups[groupId].steps = currItem.stages[stageId].groups[groupId].steps.filter((_, index) => index !== stepId);
          setCurrItem({...currItem});
          }}>
          <Delete color='error' />
        </IconButton>
        {currItem.stages[stageId].valueType == 'POSTBACK' && (<>
          {currItem.stages[stageId].groups[groupId].steps[stepId].rewardType === 'summary'
          && currItem.stages[stageId].groups[groupId].steps[stepId].reward
          % currItem.stages[stageId].groups[groupId].steps[stepId].value !== 0 && (
            <ErrorIcon color='error' />
          )}
          <Typography style={{ marginLeft: '20px'}}><b>Single reward amount:</b></Typography>&nbsp;
          {
            currItem.stages[stageId].groups[groupId].steps[stepId].rewardType === 'summary' ? (
              <>
                <Typography>$
                  {roundToUsd(currItem.stages[stageId].groups[groupId].steps[stepId].reward
                  / currItem.stages[stageId].groups[groupId].steps[stepId].value)}
                </Typography>
              </>
          ) : (
            <Typography>
              ${roundToUsd(currItem.stages[stageId].groups[groupId].steps[stepId].reward / currItem.stages[stageId].groups[groupId].steps[stepId].value * 0.8)}
              -
              ${roundToUsd(currItem.stages[stageId].groups[groupId].steps[stepId].reward / currItem.stages[stageId].groups[groupId].steps[stepId].value * 1.2)}
            </Typography>
          )}
        </>)}
      </Box>
    ))
  }

  useEffect(() => {
    setCurrItem(updatableObject);
  }, [updatableObject]);

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        fullScreen
      >
        {currItem ? 
          <DialogContent className={classes.mainContainer}>
            <DialogTitle textAlign={'center'}>
              {isCreate ? "Creating new: " : updateItemAction ? "Editing: " : `Preview: Iteration: ${currItem.stageOrder + 1}, Version: ${currItem.stageVersion}`}
            </DialogTitle>
            <DialogContentText id="alert-dialog-description">
              <Box style={{ marginBottom: '5px'}}>
                <Box style={{ display: 'flex', alignItems:'center'}}>
                  <TextField
                    disabled={!updateItemAction}
                    defaultValue={currItem.startingBonus / 100}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    style={{ marginBottom: '5px', marginRight: '5px' }}
                    label="Starting Bonus"
                    name="value"
                    size='small'
                    variant='outlined'
                    onBlur={(e) => {
                      currItem.startingBonus = +e.target.value * 100;
                      setCurrItem({...currItem});
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <Tooltip title="" style={{ marginLeft: '10px' }}>
                    <Checkbox
                      icon={<PaymentsOutlined />}
                      checkedIcon={<Payments />}  
                      defaultChecked={currItem.bointsCashoutAllowed}
                      onClick={() => currItem.bointsCashoutAllowed = !currItem.bointsCashoutAllowed}
                    />
                  </Tooltip>
                </Box>

                {StageRender(currItem.stages)}
                <Button style={{display: updateItemAction ? "block" : "none"}} onClick={() => {
                  currItem.stages.push({
                    id: Math.random(),
                    offerwall: 'ADJOE',
                    valueType: 'BOINTS',
                    groups: [],
                  } as any);
                  setCurrItem({...currItem});
                }}>Add Stage</Button>
              </Box>
            </DialogContentText>
          </DialogContent>
        : <DialogContent style= {{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <CircularProgress/>
          </DialogContent>}
        <DialogActions style={{ boxShadow: '-4px 0 6px black', zIndex: 50 }}>
          <Typography style={{ width: '100%' }}>Rewards total: ${roundToUsd(getRewardsSum())}</Typography>
          <Button onClick={handleCloseModal} color="secondary" style={{ display: updateItemAction ?  "block" : "none"}}>
            Discard
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            {updateItemAction ? "Save" : "Close"}
          </Button>
        </DialogActions>
      </Dialog>
      {btnText ? <Button
        onClick={() => handleClickOpen()}
        style={{textTransform: 'none'}}
        variant='text'
        color='inherit'>
        {btnText}
        <Launch style={{ fontSize: '0.9rem' }} />
      </Button> : 
      <IconButton onClick={() => handleClickOpen()} color={isCreate ? "success" : "primary"} disabled={disabled}>
        {isCreate ? <AddIcon /> : <Edit />}
      </IconButton>
      }
          <Box>
      <Dialog
        open={showErrors.length > 0}
        onClose={() => setShowErrors([])}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Errors:</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {showErrors.map((error) => (<Box className={classes.errorLabel}>{error}</Box>))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowErrors([])} color="primary" autoFocus>
            Keep Editing
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </Box>
  );
};
