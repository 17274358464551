import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/app';
import { Box, Button, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { getSettingsStyle } from './frontend-settings.styles';
import { JsonEditor } from './components/json-editor';

export const FrontendSettings = observer(() => {
  const { settingsStore } = useStores();
  const classes = getSettingsStyle();
  
  const [version, setVersion] = useState('');
  const [currentVersion, setCurrentVersion] = useState('');
  const [settings, setSettings] = useState(settingsStore.frontendSettings);

  useEffect(() => {
    settingsStore.getAllFrontendSettingsVersions();
  }, [settingsStore])

  useEffect(() => {
    setSettings(settingsStore.frontendSettings);
  }, [settingsStore.frontendSettings]);

  useEffect(() => {
    if (settingsStore.frontendSettingsVersions.length > 0) {
      setCurrentVersion(settingsStore.frontendSettingsVersions[0]);
    }
  }, [settingsStore.frontendSettingsVersions]);

  const updateFrontendSettings = () => {
    settingsStore.updateFrontendSettings({ settings, version: currentVersion });
  }

  const createFrontendSettings = () => {
    settingsStore.updateFrontendSettings({ settings, version });
  }
  
  const handleCurrentVersionChange = (event: SelectChangeEvent<string>) => {
    setCurrentVersion(event.target.value);
  }

  const handleDeleteCurrent = () => {
    settingsStore.deleteFrontendSettings({ version: currentVersion });
  }

  useEffect(() => {
    if (currentVersion && currentVersion.length) {
      settingsStore.getFrontendSettings({ version: currentVersion });
    }
  }, [currentVersion])

  return (
    <div className={classes.container}>
      <div className="row align-items-center">
        <div className="col-12">
          <h2>Frontend Settings</h2>
        </div>
      </div>
      <div className="row flex-nowrap">
        <div className="col-12 flex-shrink-1">
          <div className="row align-items-center">
            <div className="col-auto">
              <Select
                id="current-version-select"
                value={currentVersion}
                label="Minimal Version"
                onChange={handleCurrentVersionChange}
              >
                {settingsStore.frontendSettingsVersions.map((version) => (
                  <MenuItem key={version} value={version}>{version}</MenuItem>
                ))}
              </Select>
            </div>
            <div className="col-auto">
              <Button variant="contained" onClick={updateFrontendSettings}>Save JSON</Button>
            </div>
          </div>
        </div>
        <div className="col-auto flex-shrink-0">
          <div className="row align-items-center">
            <div className="col-auto">
              Create new version:
            </div>
            <div className="col-auto">
              <TextField
                label="Version"
                value={version}
                onChange={(e) => setVersion(e.target.value)}
              />
            </div>
            <div className="col-auto">
              <Button variant="contained" onClick={createFrontendSettings}>Create</Button>
            </div>
          </div>
        </div>
        <div className="col-auto flex-shrink-0">
          <Button variant="contained" onClick={handleDeleteCurrent} color='error'>Delete</Button>
        </div>
      </div>
      <div className="row">
        <Box className="col-12" paddingTop='16px'>
          <h4>Settings</h4>
        </Box>
        <Box className="col-12" paddingTop='4px'>
          <JsonEditor value={settingsStore.frontendSettings} onChange={setSettings} />
        </Box>
      </div>
    </div>
  );
});
